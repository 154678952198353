<template>
  <div>
    <portal to="v-main">
      <report-dialog v-model="showReportDialog" :id="idEditReport" :object_="data" />
    </portal>
    <v-card-text style="height: 300px; overflow: auto" class="pa-0">
      <a-table-f-api
        ref="tableReports"
        api="/mechti/construction-reports"
        :model="getModelList($store.getters['config/get'].models.objectReports)"
        :searchable="false"
        :defaults="{
          filters,
          sort: { key: 'id', order: 'DESC' },
          paramName: 'objectReport',
        }"
        @click="onClickReportRow($event)"
      >
        <template v-slot:top>
          <v-fab-transition v-if="canAdd">
            <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createNewReport">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    reportDialog: () => import("@/views/dialogs/objectReportDialog"),
  },
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      idEditReport: 0,
      showReportDialog: false,
    };
  },
  created() {},
  watch: {
    showReportDialog() {
      if (!this.showReportDialog) {
        this.$refs.tableReports.updateData();
      }
    },
  },
  computed: {
    filters() {
      let f = { object_id: this.id };
      if (this.$root.profile.role > 20) {
      } else {
        f.client_visible = 1;
      }
      return f;
    },
    canAdd() {
      if (this.$root.profile.role == 20) return false;
      return true;
    },
  },
  methods: {
    onClickReportRow(d) {
      this.idEditReport = d.row.id;
      this.showReportDialog = true;
    },
    createNewReport() {
      this.idEditReport = 0;
      this.showReportDialog = true;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
